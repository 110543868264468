.root {
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  :global {
    .hidden {
      display: none !important;
    }
    .floatAudio {
      display: flex;
      align-items: center;
      z-index: 98;
      position: fixed;
      bottom: 2rem;
      right: 2rem;
      img {
        width: 1rem;
        margin-right: 0.75rem;
        cursor: pointer;
      }
    }
    .bg0 {
      background-color: #d9ebfe;
    }
    .bg1 {
      background-color: #e5f4c0;
    }
    .bg2 {
      background-color: #d9ebfe;
    }
    .bgCell0 {
      background-color: #f0f7ff;
    }
    .bgCell1 {
      background-color: #f5fbe6;
    }
    .bgCell2 {
      background-color: #f0f7ff;
    }
    .topBar {
      height: 30px;
      font-size: 0.875rem;
      text-align: center;
      line-height: 30px;
      background-color: #FAAD14;
      color: white;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1.56rem 0.5rem 4.25rem;
      box-shadow: 0px 2px 6px 0px rgba(50, 64, 77, 0.14);
      border-bottom: 1px solid #f0f2f5;
      .userName {
        display: flex;
        align-items: center;
        justify-content: center;
        .userNameText {
          display: flex;
          align-items: center;
        }
        .logout {
          color: #666;
          margin-left: 1.25rem;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        img {
          margin-right: 0.2rem;
          width: 1rem;
          height: auto;
        }
      }
      img {
        width: 7rem;
      }
    }
    .contentBox {
      background-color: #f0f2f5;
      padding: 1rem;
      flex: 1;
      .searchBar {
        background-color: white;
        padding: 0.75rem 1rem;
        border-radius: 0.375rem;
        display: flex;
        align-items: center;
        justify-content: left;
        .inputBox {
          display: flex;
          align-items: center;
          padding-right: 1.5rem;
          img {
            margin-left: 0.35rem;
            cursor: pointer;
            width: 1rem;
            height: 1rem;
          }
          .inputWrap,
          .pickerWrap {
            width: 11rem;
            .ant-picker-range {
              font-size: 0.75rem;
              border-radius: 0.125rem;
            }
            .myInput {
              border-radius: 0.125rem;
            }
            input {
              min-height: 1.5rem;
              font-size: 0.75rem;
            }
          }
          .pickerWrap {
            width: 20rem;
          }
        }
        .btnBox {
          display: flex;
          align-items: center;
          .resetImg {
            background-color: rgba(0, 0, 0, 0.4);
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            img {
              cursor: pointer;
              width: 0.875rem;
              height: 0.875rem;
            }
          }
          .searchImg {
            background-color: #fd0000;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;
            img {
              cursor: pointer;
              width: 1.3rem;
              height: 1.3rem;
            }
          }
        }
      }
      .myTable {
        .welcome {
          font-size: 1rem;
        }
        div {
          box-sizing: border-box;
        }
        margin-top: 1rem;
        padding: 1.25rem 1rem;
        border-radius: 0.125rem;
        background-color: white;
        .patientList {
          display: flex;
          .flex7 {
            width: 36.75rem;
            display: flex;
            align-items: flex-end;
            .title {
              font-size: 0.875rem;
              margin-bottom: 0.75rem;
              font-weight: 500;
            }
          }
          .flex9 {
            display: flex;
            border-right: 1px solid #d8dae2;
            .empytDiv {
              width: 16.4375rem;
            }
            .patientWrap {
              display: flex;
              width: 16.4375rem;
              border: 1px solid #d8dae2;
              border-right: none;
              border-bottom: none;
              .patientInfo {
                width: 16.4375rem;
                .patientBox {
                  padding: 0.5rem 1rem;
                  word-break: break-all;
                  .numText {
                    display: flex;
                    justify-content: left;
                    .boldText {
                      font-weight: 500;
                      flex: 5;
                    }
                    .valueText {
                      flex: 6;
                    }
                  }
                  .numText:first-child {
                    margin-bottom: 0.25rem;
                  }
                }
                .patientBox:first-child {
                  border-bottom: 1px solid #d8dae2;
                }
              }
            }
          }
        }
        .questionList {
          font-size: 0.875rem;
          .title {
            margin-top: 0.875rem;
            margin-bottom: 0.75rem;
            font-weight: 500;
          }
          .questionTable {
            border: 1px solid #030f4029;
            border-radius: 0.25rem;
            .headBar {
              background-color: #030f4029;
              font-weight: 500;
              height: 2.2rem;
            }
            .hoverRow:hover {
              background-color: #fdede4;
              .cells {
                background-color: #fdede4 !important;
              }
            }
            .row {
              display: flex;
              .longCell {
                width: 36.62rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #030f4029;
                .cellDiv {
                  padding: 0.5rem 1rem;
                }
              }
              .scoreCell {
                display: flex;
                width: 16.4375rem;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #030f4029;
              }
              .middleCell {
                width: 11.025rem;
                display: flex;
                align-items: center;
                justify-content: left;
                border-bottom: 1px solid #030f4029;
                border-right: 1px solid #030f4029;
                .cellDiv {
                  padding: 0.5rem 1rem;
                }
              }
              .bigCell {
                width: 25.725rem;
                // === Added Start：Feature Combine Answers - 20240617 - PennYao ===
                display: flex;
                align-items: center;
                // === Added End：Feature Combine Answers ===
                border-bottom: 1px solid #030f4029;
                border-right: 1px solid #030f4029;
                .cellDiv {
                  padding: 0.5rem 1rem;
                }
              }
              .cells {
                display: flex;
                // width: 16.4375rem;
                .onPlaying {
                  background-color: #fdede4;
                }
                .cell {
                  width: 3.4792rem;
                  border-bottom: 1px solid #030f4029;
                  border-right: 1px solid #030f4029;
                  align-items: center;
                  display: flex;
                  justify-content: center;

                  .cellDiv {
                    // padding: 0.5rem 1rem;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    .playAllVoice {
                      width: 1rem;
                      margin-left: 0.125rem;
                      cursor: pointer;
                    }

                    .wenben {
                      width: 0.9rem;
                      cursor: pointer;
                    }
                    .voice {
                      width: 1.25rem;
                      cursor: pointer;
                    }
                  }
                }
                .cell2 {
                  width: 9.4792rem;
                  // .myAudio {
                  //   transform: scale(0.7, 0.7);
                  // }
                }
              }
            }
          }
          .remarkList {
            display: flex;
            border: 1px solid #030f4029;
            border-radius: 0.25rem;
            .head {
              width: 11.025rem;
              display: flex;
              align-items: center;
              justify-content: left;
              div {
                padding: 0 1rem;
              }
            }
            .assessRight {
              .hoverRow2:hover {
                background-color: #fdede4;
                .cell,
                .textAreaWrap {
                  background-color: #fdede4 !important;
                }
              }
              .row {
                display: flex;
                .bigCell {
                  width: 25.725rem;
                  border-bottom: 1px solid #030f4029;
                  border-left: 1px solid #030f4029;
                  .cellDiv {
                    padding: 0.5rem 1rem;
                  }
                }
                .cell:last-child {
                  border-right: 1px solid #030f4029;
                }
                .cell {
                  width: 16.4375rem;
                  border-bottom: 1px solid #030f4029;
                  border-left: 1px solid #030f4029;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .cellDiv {
                    padding: 0;
                  }
                }
                .textCell:last-child {
                  border-right: 1px solid #030f4029;
                }
                .textCell {
                  width: 16.4375rem;
                  border-left: 1px solid #030f4029;
                  display: flex;
                  align-items: center;
                  justify-content: left;
                  .cellDiv {
                    padding: 0.5rem 1rem;
                    .myInput {
                      border: none !important;
                      font-size: 0.875rem;
                    }
                  }
                  .remarkText {
                    width: 100%;
                  }
                }
                .textAreaWrap {
                  position: relative;
                  .textAreaPad {
                    padding-bottom: 1.7rem !important;
                  }
                  .alreadyInput {
                    font-size: 0.75rem;
                    text-align: right;
                    position: absolute;
                    bottom: 0.5rem;
                    right: 0.5rem;
                    opacity: 0.7;
                  }
                }
              }
            }
          }
          .noBorderTop {
            border-top: none !important;
          }
          .noBorderRight {
            border-right: none !important;
          }
          .noBorderBottom {
            border-bottom: none !important;
          }
        }
        .barTop {
          margin-top: 1rem;
        }
        .barBottom {
          margin-bottom: 1rem;
        }
        .pageBar {
          display: flex;
          align-items: center;
          justify-content: right;
          .pageBtnGroup {
            display: flex;
            justify-content: space-between;
            width: 4rem;
            margin-left: 1.5rem;
            margin-right: 1rem;
            font-size: 0.875rem;
            div {
              cursor: pointer;
            }
            .disabled {
              opacity: 0.5;
              cursor: not-allowed !important;
            }
            img {
              width: 0.7rem;
            }
            .rightArrow {
              img {
                transform: rotate(180deg);
              }
            }
          }
          .inputBox {
            display: flex;
            align-items: center;
            padding-right: 1.5rem;
            .inputWrap {
              margin-left: 0.5rem;

              input {
                font-size: 0.875rem;
                border-radius: 0.125rem;
                width: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}