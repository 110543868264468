.myAudio {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}

.audioControl {
  margin-right: 0.5rem;
  cursor: pointer;
}

.audioControl img {
  width: 1rem;
}

input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 8px;
  background: #ccc;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: #707070; /* 滑块颜色 */
  cursor: pointer;
}

/* === Deleted: Fix: Rule#css:S4666(Line#36) - 20240710 - By PennYao === */