.ant-tooltip-inner {
  color: #333 !important;
  background-color: #fff !important;
  font-size: 0.875rem;
  min-height: auto !important;
}

.ant-tooltip-arrow-content {
  background-color: #fff !important;
}

.ant-tooltip-arrow::before {
  background-color: #fff !important;
}

.mySelect {
  /* === Updated Start：Fix Bug#22 - 20241106 - By PennYao === */
  width: 3.0rem;
  /* === Updated End：Fix Bug#22 - 20241106 - By PennYao === */
}

.mySelect.remark {
  /* === Updated Start：Fix Bug#22 - 20241204 - By PennYao === */
  width: 4.5rem;
  /* === Updated End：Fix Bug#22 - 20241204 - By PennYao === */
}

.mySelect .ant-select-selector {
  border: none !important;
}

.mySelect .ant-select-selection-item {
  text-align: center;
  font-size: 0.875rem;
}

.mySelect
  :where(.css-dev-only-do-not-override-1qfezbu).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: inherit;
}

.remarkText :where(.css-dev-only-do-not-override-1qfezbu).ant-input-outlined {
  background-color: inherit;
}

.answerWrap {
  font-size: 0.875rem;
  overflow-y: scroll;
  max-height: 70vh;
}

.ant-modal-confirm-title {
  font-size: 1rem !important;
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-modal-confirm
  .ant-modal-confirm-body-has-title
  > .anticon {
  margin-top: 0.25rem;
}
