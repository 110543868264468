@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba($color: #ccc, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 2rem;
    animation: rotate 2s linear infinite;
  }
}
