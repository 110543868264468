.root {
  :global {
    .login {
      position: absolute;
      width: 24rem;
      bottom: -3rem;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 15px;
      background-color: #ffffff;
      box-shadow: -3px 2px 46px 5px rgba(3, 15, 64, 0.3);
      .loginHeader {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 1.25rem 1.56rem 2.5rem;
        .logo {
          width: 6.25rem;
          margin-right: 0.375rem;
        }
        .logoTitle {
          padding-left: 0.625rem;
          border-left: 1px solid #fe0000;
          font-weight: 600;
        }
      }
      .inputGroup {
        padding: 0 1.56rem;
        .inputBox {
          position: relative;
          .input {
            width: 100%;
            margin-bottom: 1.5rem;
            border: none;
            border-bottom: 1px solid rgba(3, 15, 64, 0.3);
            font-size: 0.875rem;
            padding-bottom: 0.5rem;
          }
          .input:focus {
            outline: none;
          }
          /* === Updated Start：Fix: Privacy Violation#(Line#83) - 20240814 - By PennYao === */
          /* Hide the default show password button in input[type="password"] */
          .input[type="password"]::-ms-reveal,
          .input[type="password"]::-ms-clear {
            display: none;
          }
          .input[type="password"]::-webkit-clear-button,
          .input[type="password"]::-webkit-inner-spin-button,
          .input[type="password"]::-webkit-outer-spin-button,
          .input[type="password"]::-webkit-search-cancel-button,
          .input[type="password"]::-webkit-search-decoration {
            display: none;
          }
          // .pwdIcon {
          //   position: absolute;
          //   right: 0;
          //   top: 10%;
          //   width: 1.125rem;
          // }
          /* === Updated End：Fix: Privacy Violation#(Line#83) - 20240814 - By PennYao === */
        }
      }
      .login_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1.25rem;
        background-color: #fe0000;
        font-size: 0.875rem;
        height: 2.5rem;
        color: #ffffff;
        margin: 0 1.56rem 2.5rem;
        cursor: pointer;
      }
      .disabled-btn {
        background-color: #ccc;
      }
      .agreeBox {
        height: 3rem;
      }
    }
    .topBar {
      height: 30px;
      font-size: 0.875rem;
      text-align: center;
      line-height: 30px;
      background-color: #FAAD14;
      color: white;
    }
    .box {
      height: 100vh;
      display: flex;
      flex-direction: column;
      .flex1 {
        // flex: 4;
        position: relative;
        height: 80%;
        .bgImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .flex2 {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 0.75rem;
        padding-bottom: 2rem;
      }
    }
  }
}

img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
